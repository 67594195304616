import {useCallback} from "react";
import useNextPagesPaths from "../../../hooks/app-pages-transition/useNextPagesPaths";
import {setLocaleClient} from "../setLocale";
import {pathnameWithoutLocale} from "../pathnameLocale";
import {SupportedLanguage} from "../types";
import {useRouter} from "next/navigation";

type LanguageChanger = (lang: SupportedLanguage) => void;

export default function useLanguageChanger(): LanguageChanger {
  const router = useRouter();
  const {asPath} = useNextPagesPaths();
  return useCallback(
    lang => {
      const currentHref = window.location.href;
      setLocaleClient(lang);
      const pathnameNoLocale = pathnameWithoutLocale(asPath);
      const pushPath = pathnameNoLocale === "/" ? `/${lang}` : `/${lang}${pathnameNoLocale}`;
      router.push(pushPath);

      // Ensure that prefetched links get cleared by refreshin the page,
      // because the results were for the prior language.
      // Use setInterval to repeatedly check for the new href, because the
      // router.push is async
      const interval = setInterval(() => {
        if (window.location.href !== currentHref) {
          router.refresh();
          clearInterval(interval);
        }
      }, 50);
    },
    [asPath, router],
  );
}
