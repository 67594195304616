import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import {DEFAULT_NS} from "@i18n/constants";
import {DEFAULT_I18N_OPTIONS} from "@i18n/options";
import {isBrowser} from "../../isBrowser";
import {fixInterpolation} from "@i18n/fixInterpolation";

// This block initializes i18n for the client side.
// Loosely based upon the following guide, section 5 (Client side):
// https://locize.com/blog/next-13-app-dir-i18n/
i18next
  .use(initReactI18next) // initialize React integration
  .use(LanguageDetector) // detect language on the client side
  .use(
    resourcesToBackend(
      // load our JSON translations into i18next
      (language: string, namespace: string) =>
        import(`../../../../locales/${language}/${namespace}.json`),
    ),
  )
  .init(
    {
      ...DEFAULT_I18N_OPTIONS,
      ns: DEFAULT_NS,
      lng: undefined, // let detect the language on client side
      detection: {
        order: ["path", "cookie", "htmlTag", "navigator"],
      },
      // Preload only when rendering client components on the server
      preload: isBrowser() ? [] : DEFAULT_I18N_OPTIONS.supportedLngs,
    },
    err => {
      if (err) {
        console.error(err);
      }
    },
  );

const i18n = {
  ...i18next,
  t: fixInterpolation(i18next.t),
};

export default i18n;
