import {useRouter} from "next/router";
import {useEffect} from "react";
import {v4} from "uuid";

import {EventType} from "../../../utils/analytics/constants";
import {queueEvent} from "../../../utils/analytics/eventQueue";
import {setTrafficId} from "../../../utils/browser-storage/trafficId";

export const usePageNavigationAnalytics = (
  initialTrafficId: string,
  referer: string | null,
): void => {
  const router = useRouter();

  useEffect(() => {
    // referer can change between null and a real URL, which would otherwise
    // trigger initial change events on navigations between pages using
    // getServerSideProps and not. If the referer is from the same domain,
    // then this must be an internal navigation that would be captured by the other
    // useEffect below. So skip any events with a referer from the same domain.
    if (referer && referer.includes(window.location.hostname)) return;

    queueEvent({
      id: initialTrafficId,
      typeId: EventType.WebsitePageChanged,
      extraData: {
        to: window.location.pathname,
        from: referer || "null",
        domain: window.location.hostname,
      },
    });
  }, [initialTrafficId, referer]);

  useEffect(() => {
    const handleRouteChange = (to: string) => {
      const trafficId = v4();
      queueEvent({
        id: trafficId,
        typeId: EventType.WebsitePageChanged,
        extraData: {
          to,
          from: window.location.pathname,
          domain: window.location.hostname,
        },
      });
      setTrafficId(trafficId);
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);
};
