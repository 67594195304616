import {getApiUrl} from "src/constants/apiUrls";
import {getToken} from "../browser-storage/tokens";
import {getVisitId, getVisitorId} from "../visitData";
import {validateHeaders} from "./monolithValidation";

const apiUrl = getApiUrl();

/**
 * Wrap "fetch" with a "fetch-like" function that has a few minor differences:
 *
 *   1. If present, the patient's auth token will be included with the request.
 *   2. The first arg must be a path, not a full URL. The URL's hostname will be
 *      hardcoded to Carbon's API to prevent accidental leaks to other domains.
 *
 * The goal here is to consolidate shared code, but still behave _like_ fetch so
 * that we don't end up maintaining abstractions on top of abstractions, and
 * frontend engineers who join the company will have a low learning curve.
 */
export function limitToMonolith(
  delegate: typeof fetch,
): (path: string, init?: RequestInit) => Promise<Response> {
  return async (path: string, init?: RequestInit) => {
    const url = `${apiUrl}${path}`;

    const token = getToken();
    const defaultHeaders = {
      ...getDefaultHeaders(init?.body),
      ...(token ? {authorization: `Bearer ${token}`} : {}),
    };

    if (init) {
      const {headers, ...otherInitOptions} = init;
      return delegate(url, {
        headers: {
          ...defaultHeaders,
          ...validateHeaders(headers),
        },
        ...otherInitOptions,
      });
    } else {
      return delegate(url, {headers: defaultHeaders});
    }
  };
}

export function getDefaultHeaders(body?: BodyInit | null) {
  // Note: Visitor data will only work here for client, because we need to
  // pass Next request on the server.
  const visitId = getVisitId();
  const visitorId = getVisitorId();
  return {
    carbonAppType: "Web",
    ...(body ? {"Content-Type": "application/json"} : {}),
    ...(visitorId ? {carbonVisitorId: visitorId} : {}),
    ...(visitId ? {carbonVisitId: visitId} : {}),
  };
}
