"use client";

import ServerOrClientTrans from "@i18n/Trans";
import useTranslation from "./useTranslation";

type TransProps = Omit<Parameters<typeof ServerOrClientTrans>[0], "t">;

export default function Trans(props: TransProps): JSX.Element {
  const {t} = useTranslation();
  return <ServerOrClientTrans t={t} {...props} />;
}
