import memoizee from "memoizee";
import {useRouter} from "next/router";
import {isBrowser} from "src/utils/isBrowser";
import {v4} from "uuid";

import {GetSchedulingRedirectUrlParams} from "../../../components/_common/types";
import {webSchedulingSpecialtyIds} from "../../../constants/specialtyIds";
import {getSchedulingRedirectUrl} from "../../../utils/getRedirectUrl";
import {ignoreArrays} from "../../../utils/ignoreArrays";
import {removeFalsyKeys} from "../../../utils/removeFalsyKeys";
import {createURLBuilder, getHostWithProtocol} from "../../../utils/urls";
import {PracticeId} from "../../../constants/practiceIds";

type QueryValue = string | string[] | undefined;

type BookingQuery = {
  "appointment-reason"?: QueryValue;
  "appointment-id"?: QueryValue;
  "initial-calendar-time"?: QueryValue;
  canceled?: QueryValue;
  canceling?: QueryValue;
  "confirmation-sent"?: QueryValue;
  doubleBookingOf?: QueryValue;
  quitting?: QueryValue;
  location?: QueryValue;
  "old-appointment-id"?: QueryValue;
  "practice-id"?: QueryValue;
  email?: QueryValue;
  time?: QueryValue;
  slotIds?: QueryValue;
  slotUnavailable?: QueryValue;
  rescheduling?: QueryValue;
  originUrl?: QueryValue;
  flowId?: QueryValue;
  region?: QueryValue;
  specialtyId?: QueryValue;
  selectedPatientId?: QueryValue;
  selectedTopic?: QueryValue;
  showBackButton?: QueryValue;
  "doctor-id"?: QueryValue;
  dvid?: QueryValue;
};

export type ParsedBookingQuery = {
  appointmentReasonSlug: string | null;
  appointmentId: string | null;
  canceled: boolean | null;
  canceling: boolean | null;
  confirmationEmailSent: boolean | null;
  doubleBookingOf: string | null;
  quitting: boolean | null;
  locationSlug: string | null;
  oldAppointmentId: string | null;
  practiceId: string;
  selectedTime: number | null;
  initialCalendarTime: number | null;
  selectedSlotIds: string[] | null;
  isSelectedTimeUnavailable: boolean | null;
  rescheduling: boolean | null;
  originUrl: string | null;
  flowId: string | null;
  region: string | null;
  specialtyId: string | null;
  selectedPatientId: string | null;
  selectedTopic: string | null;
  showBackButton: boolean | null;
  doctorId?: string | null;
  dropoutVisitorId?: string | null;
};

export const parseBookingQuery = memoizee(
  (query: BookingQuery): ParsedBookingQuery => {
    const selectedTime = ignoreArrays(query.time);
    const originUrl = ignoreArrays(query.originUrl);
    const slotIds = ignoreArrays(query.slotIds);
    const initialCalendarTime = ignoreArrays(query["initial-calendar-time"]);
    return {
      appointmentReasonSlug: ignoreArrays(query["appointment-reason"]),
      appointmentId: ignoreArrays(query["appointment-id"]),
      canceled: safeBoolParse(query["canceled"]),
      canceling: safeBoolParse(query["canceling"]),
      confirmationEmailSent: safeBoolParse(query["confirmation-sent"]),
      doubleBookingOf: ignoreArrays(query["doubleBookingOf"]),
      quitting: safeBoolParse(query["quitting"]),
      locationSlug: ignoreArrays(query.location),
      oldAppointmentId: ignoreArrays(query["old-appointment-id"]),
      practiceId: ignoreArrays(query["practice-id"]) ?? PracticeId.CARBON,
      selectedTime: selectedTime ? parseInt(selectedTime) : null,
      initialCalendarTime: initialCalendarTime ? parseInt(initialCalendarTime) : null,
      selectedSlotIds: slotIds ? JSON.parse(decodeURIComponent(slotIds)) : null,
      isSelectedTimeUnavailable: ignoreArrays(query.slotUnavailable) === "true",
      rescheduling: ignoreArrays(query.rescheduling) === "true",
      originUrl: originUrl ? decodeURIComponent(originUrl) : null,
      flowId: ignoreArrays(query.flowId),
      region: ignoreArrays(query.region),
      specialtyId: ignoreArrays(query.specialtyId),
      selectedPatientId: ignoreArrays(query.selectedPatientId),
      selectedTopic: ignoreArrays(query.selectedTopic),
      showBackButton: safeBoolParse(query.showBackButton),
      doctorId: ignoreArrays(query["doctor-id"]),
      dropoutVisitorId: ignoreArrays(query.dvid),
    };
  },
  {
    normalizer: JSON.stringify,
  },
);

function urlWithMaybeDefaultHost(url: string): URL {
  try {
    // If this works, it was an absolute URL
    return new URL(url);
  } catch (e) {
    // Must have been a relative URL, add the origin
    return new URL(url, getHostWithProtocol());
  }
}

export const parseBookingQueryFromUrl = memoizee((url: string) => {
  const urlObj = urlWithMaybeDefaultHost(url);
  const query = Object.fromEntries(urlObj.searchParams.entries());
  return parseBookingQuery(query);
});

export const useParsedBookingQuery = () => {
  const router = useRouter();
  return parseBookingQuery(router.query);
};

const falsifyBadStrings = (val: unknown) => {
  const string = `${val}`;
  return string === "undefined" ? undefined : string === "null" ? null : string;
};

export const buildBookingLinkQuery = memoizee(
  (query: Partial<ParsedBookingQuery>) =>
    removeFalsyKeys({
      "appointment-reason": query.appointmentReasonSlug || null,
      "old-appointment-id": query.oldAppointmentId || null,
      "appointment-id": query.appointmentId || null,
      "initial-calendar-time": falsifyBadStrings(query.initialCalendarTime) || null,
      canceled: falsifyBadStrings(query.canceled) || null,
      canceling: falsifyBadStrings(query.canceling) || null,
      "confirmation-sent": falsifyBadStrings(query.confirmationEmailSent) || null,
      doubleBookingOf: query.doubleBookingOf || null,
      quitting: falsifyBadStrings(query.quitting) || null,
      location: query.locationSlug || null,
      "practice-id": query.practiceId || null,
      flowId: query.flowId || null,
      slotIds: query.selectedSlotIds
        ? encodeURIComponent(JSON.stringify(query.selectedSlotIds))
        : null,
      time: falsifyBadStrings(query.selectedTime) || null,
      slotUnavailable: falsifyBadStrings(query.isSelectedTimeUnavailable) || null,
      rescheduling: falsifyBadStrings(query.rescheduling) || null,
      originUrl: query.originUrl || null,
      region: query.region || null,
      specialtyId: query.specialtyId || null,
      selectedPatientId: query.selectedPatientId || null,
      selectedTopic: query.selectedTopic || null,
      showBackButton: query.showBackButton || null,
      "doctor-id": query.doctorId || null,
      dvid: query.dropoutVisitorId || null,
    }),
  {
    normalizer: JSON.stringify,
  },
);

export const buildBookingLink = memoizee(
  (query: Partial<ParsedBookingQuery>) =>
    createURLBuilder("/booking").setQueryParams(buildBookingLinkQuery(query)).build(),
  {
    normalizer: JSON.stringify,
  },
);

export const startBookingFlow = (query: Partial<ParsedBookingQuery>): [string, string] => {
  const flowId = v4();
  return [buildBookingLink({...query, flowId}), flowId];
};

export const useCurrentFlowId = () => {
  const {query} = useRouter();
  return ignoreArrays(query.flowId);
};

export const getSelectedPatientId = () => {
  const search = isBrowser() ? window.location.search : undefined;
  const searchParams = new URLSearchParams(search);
  return Object.fromEntries(searchParams.entries()).selectedPatientId;
};

const safeBoolParse = (param?: string | string[]) => {
  if (param === "true") {
    return true;
  } else if (param === "false") {
    return false;
  }
  return null;
};

export const getSchedulingLink = ({
  webSchedulingQuery,
  specialtyIds,
  patientAppSchedulingQuery,
}: {
  specialtyIds: string[];
  webSchedulingQuery: Partial<ParsedBookingQuery>;
  patientAppSchedulingQuery: GetSchedulingRedirectUrlParams;
}) => {
  const WEB_SCHEDULING_ENABLED = specialtyIds.some(specialtyId =>
    webSchedulingSpecialtyIds.includes(specialtyId),
  );
  const [webSchedulingLink, flowId] = startBookingFlow(webSchedulingQuery);

  const bookingLink = WEB_SCHEDULING_ENABLED
    ? webSchedulingLink
    : getSchedulingRedirectUrl({...patientAppSchedulingQuery, flowId});

  return {bookingLink, flowId, WEB_SCHEDULING_ENABLED};
};
