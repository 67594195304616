import {isBrowser} from "../utils/isBrowser";

export const ApiUrl = {
  PROD: "https://api.carbonhealth.com",
  STAGING: "https://api.staging.carbonhealth.com",
  LOCAL: "http://localhost:9000",
  PROD_GRAPHQL: "https://graphql.api.carbonhealth.com/graphql",
  STAGING_GRAPHQL: "https://graphql.api.staging.carbonhealth.com/graphql",
  LOCAL_GRAPHQL: "http://localhost:4000/graphql",
} as const;

export function getApiUrl() {
  try {
    // for staging: if server, check process.env vars. if browser, check window
    if (
      (SITE_URL && SITE_URL.includes("staging")) ||
      (isBrowser() && window.location.host === "staging.carbonhealth.com") ||
      process.env.DB === "stagingDB"
    ) {
      return apiUrlStaging;
    }
    // for local: just check node env var in package.json
    if (process.env.DB === "localDB") {
      return apiUrlLocal;
    }
    return apiUrlProd;
  } catch (err) {
    return apiUrlProd;
  }
}

export function getGraphQLUrl() {
  switch (getApiUrl()) {
    case apiUrlProd:
      return ApiUrl.PROD_GRAPHQL;
    case apiUrlStaging:
      return ApiUrl.STAGING_GRAPHQL;
    case apiUrlLocal:
      return ApiUrl.LOCAL;
    default:
      return ApiUrl.PROD_GRAPHQL;
  }
}

// this should be used in server only to download data.
export function getApiUrlForScripts() {
  try {
    if (SITE_URL && SITE_URL.includes("staging")) {
      return apiUrlStaging;
    }
    if (process.env.DOWNLOAD_PRACTICE_DATA_LOCAL) {
      return apiUrlLocal;
    }
    return apiUrlProd;
  } catch (err) {
    return apiUrlProd;
  }
}
export const apiUrlProd = ApiUrl.PROD;
export const apiUrlStaging = ApiUrl.STAGING;
export const apiUrlLocal = ApiUrl.LOCAL;
export const SITE_URL = process.env.SITE_URL;
