import {TranslateFn} from "./types";

type TranslateOptions<T = string> =
  | (Omit<Parameters<TranslateFn>[2], "default"> & {
      default?: T;
    })
  | undefined;
type I18nKey = Parameters<TranslateFn>[0];
const addDefaultToOptions = <T>(options: TranslateOptions<T>, i18nKey: I18nKey) => ({
  ...options,
  default: options?.default || (typeof i18nKey === "string" && i18nKey) || undefined,
});

export const fixInterpolation =
  (t: TranslateFn): TranslateFn =>
  (i18nKey, options) =>
    // @ts-expect-error For some reason, the i18nKey type is not compatible with itself,
    //  seems to be something with generic types that we're not using.
    t(i18nKey, addDefaultToOptions(options, i18nKey));
