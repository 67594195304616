import "../extensions";
import "../../wdyr";
import "../../public/static/css/tailwind.css";

import {ApolloProvider} from "@apollo/client";
import {OverlayProvider} from "@react-aria/overlays";
import * as Sentry from "@sentry/nextjs";
import {setUseWhatChange} from "@simbathesailor/use-what-changed";
import i18n from "i18next";
import moment from "moment";
import momentTZ from "moment-timezone";
import {AppProps} from "next/app";
import {useTranslation} from "@i18n/client";
import React, {useEffect} from "react";
// eslint-disable-next-line no-restricted-imports
import {initReactI18next} from "react-i18next";
import {Provider as ReduxProvider} from "react-redux";
import {ignoreArrays} from "src/utils/ignoreArrays";

import client from "../_services/graphql/client";
import {CarbonPage} from "../CarbonPage";
import {dev} from "../components/_common/_constants";
import {useEffectAsync} from "../components/_common/Carbon";
import {setPracticeData} from "../components/_common/setBootState";
import {MsMap} from "../constants/MsMap";
import {useGeolocateUser} from "../hooks/useGeolocateUser";
import {useStartSession} from "../hooks/analytics/pages";
import {useSetKeyboardUser} from "../hooks/useWatchKeyboardUse";
import {NextReduxWrapper} from "../store";
import {freezeTime} from "../utils/freezeTime";
import ErrorFallback from "./_error";
import Script from "next/script";
import {GTM_ID, pageview} from "../gtm";
import {useRouter} from "next/router";
import {getVisitorId} from "src/utils/visitData";
import {appWithTranslation} from "next-i18next";

setUseWhatChange(dev);

i18n.use(initReactI18next).init();

type Props = AppProps &
  Partial<{
    maybeSkipSortLocations: boolean;
    clientIp: string | null;
    referer: string | null;
  }>;

// Note: When this renders on the server in page with getServerSideProps,
//  it does NOT call getInitialProps and so props may all come as undefined.
//  That is why props have been updated to use Partial<>.
function MyApp(props: Props) {
  const {
    Component,
    pageProps,
    router: {
      query: {freezeTimeValue},
    },
    clientIp,
    referer,
  } = props;

  const {lang} = useTranslation();
  const router = useRouter();
  const visitorId = getVisitorId();

  // referer ?? null makes it actually string or null, and not potentially
  // change between undefined and null
  useStartSession(clientIp || undefined, lang, referer ?? null);

  useEffectAsync(async () => {
    window.lng = window.__NEXT_DATA__.locale;
    if (lang && lang !== "en" && lang !== "en-US") {
      let lngPath = lang;
      if (lang === "zh") {
        lngPath = "zh-cn";
      }
      await import(`moment/locale/${lngPath}`);
      moment.locale(lngPath); // apply it to moment
      moment.updateLocale(lngPath, null); // copy locale to moment-timezone
      momentTZ.locale(lngPath); // apply it to moment-timezone
    }

    if (freezeTimeValue && typeof freezeTimeValue === "string") {
      freezeTime(parseInt(freezeTimeValue, 10));
    }
  }, []);

  useEffect(() => {
    if (visitorId) {
      Sentry.setUser({id: visitorId});
    }
  }, [visitorId]);

  useSetKeyboardUser();
  useGeolocateUser();

  // force full reload every 3 hours to invalidate caches (eg: feature flags)
  useEffect(() => {
    setTimeout(() => {
      location.reload();
    }, MsMap.ONE_HOUR * 3);
  }, []);

  useEffect(() => {
    if (dev) return;
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      {!dev && (
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
          }}
        />
      )}
      <Component {...pageProps} />
    </>
  );
}

const AppWrapper = (props: Props) => {
  const {store} = NextReduxWrapper.useWrappedStore(props);

  return (
    // @ts-expect-error TS2769: No overload matches this call.
    <Sentry.ErrorBoundary FallbackRender={ErrorFallback}>
      <ApolloProvider client={client}>
        <OverlayProvider>
          <ReduxProvider store={store}>
            <MyApp {...props} />
          </ReduxProvider>
        </OverlayProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
};

/**
 * Used to allow async functions to run in parallel,
 * but ensure that first argument will always be pageProps
 */
const getEmptyPromise = () => Promise.resolve({});
AppWrapper.getInitialProps = NextReduxWrapper.getInitialAppProps(
  store =>
    async ({Component, ctx}) => {
      const {getInitialProps = getEmptyPromise, optOutOfPracticeData} = Component as CarbonPage;

      const clientIp =
        ignoreArrays(ctx.req?.headers && ctx.req.headers["x-real-ip"]) ||
        ctx.req?.socket?.remoteAddress ||
        null;
      const shouldSetPracticeData = !optOutOfPracticeData;

      if (shouldSetPracticeData) await setPracticeData(store.getState().config, store.dispatch);

      return {
        pageProps: await getInitialProps({...ctx, store}),
        clientIp,
        referer: ctx.req?.headers.referer || null,
      };
    },
);

export default appWithTranslation(AppWrapper);
