import {DEFAULT_NS} from "@i18n/constants";
import type {i18n} from "i18next";
import {useMemo} from "react";
// eslint-disable-next-line no-restricted-imports
import {useTranslation as reactI18nextUseTranslation} from "react-i18next";
import {I18nNamespace} from "@i18n/constants";
import {fixInterpolation} from "@i18n/fixInterpolation";
import {useAppClientLanguage} from "./AppClientLanguage";

function useTranslationRaw(maybeLng: string | undefined, ns: I18nNamespace) {
  const useTranslationReturn = reactI18nextUseTranslation(ns, undefined);

  // Change the language setting in i18next if the language changes from the provider
  const {i18n} = useTranslationReturn;
  const lng = maybeLng ?? i18n.language;
  if (lng && i18n.resolvedLanguage !== lng) {
    i18n.changeLanguage(lng);
  }

  return useTranslationReturn;
}

type i18nWithLang = i18n & {lang?: string};

/**
 * A wrapper around react-i18next's useTranslation that fixes interpolation
 * and supplies the language from the AppClientLanguage provider.
 *
 * @see https://react.i18next.com/latest/usetranslation-hook
 */
const useTranslation = (namespace?: I18nNamespace): i18nWithLang => {
  const {lang} = useAppClientLanguage();

  const [tWithBadInterpolation, i18n] = useTranslationRaw(lang, namespace ?? DEFAULT_NS);

  return useMemo<i18nWithLang>(() => {
    if (!i18n.isInitialized) return {...i18n, lang};

    const t = fixInterpolation(tWithBadInterpolation);

    return {
      ...i18n,
      lang: i18n.language,
      t,
    };
  }, [tWithBadInterpolation, i18n.isInitialized, i18n.language, lang]);
};

export default useTranslation;
