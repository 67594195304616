const ignorableErrors = [
  /Failed to register a ServiceWorker for scope/,
  /AbortError: Fetch is aborted/,
  /AbortError: The user aborted a request./,
  /TypeError: Load failed/,

  // These 3 are spamming sentry, but we _should_ fix them & stop ignoring.
  // Related JIRA tickets: GROWTHRND-1378, GROWTHRND-1379, GROWTHRND-1380.
  /Hydration failed because the initial UI does not match what was rendered on the server/,
  /There was an error while hydrating/,
  /Minified React error #418/,
];

export const isIgnorableError = (errorMsg: string) =>
  ignorableErrors.some(regex => regex.test(errorMsg));
