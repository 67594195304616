import {isBrowser} from "src/utils/isBrowser";
import {usePostEventQueue} from "../../../utils/analytics/usePostEventQueue";
import {usePageNavigationAnalytics} from "./usePageNavigationAnalyticsPages";
import useSessionState from "../common/useSessionState";
import initializeSession from "../common/initializeSession";

export const useStartSession = (
  ipAddress: string | undefined,
  lng: string | undefined,
  referer: string | null,
) => {
  const sessionState = useSessionState(ipAddress, lng);

  if (isBrowser()) {
    initializeSession(sessionState);
  }

  usePageNavigationAnalytics(sessionState.trafficId, referer);
  usePostEventQueue();
};
