import {setCookie} from "cookies-next";
import {AnyNextRequest} from "./nextRequestType";
import {IncomingMessage, ServerResponse} from "http";
import {CookieKey, expiresInOneYearOptions, getCookieValue} from "./cookies";

export const setVisitId = (id: string) => {
  setCookie(CookieKey.VisitId, id, expiresInOneYearOptions());
};

export const setVisitorId = (id: string) => {
  setCookie(CookieKey.VisitorId, id, expiresInOneYearOptions());
};

export const getVisitId = (req?: AnyNextRequest, res?: ServerResponse<IncomingMessage>) =>
  getCookieValue(CookieKey.VisitId, req, res);

export const getVisitorId = (req?: AnyNextRequest, res?: ServerResponse<IncomingMessage>) =>
  getCookieValue(CookieKey.VisitorId, req, res);
