import isbot from "isbot";
import {useEffect} from "react";

import {getPublicEnv} from "../../../utils/public-env";
import {getCurrentUserId} from "../browser-storage/currentUserId";
import {getDevice} from "../browser-storage/device";
import {addToEventQueue, clearEventQueue, getEventQueue} from "../browser-storage/eventQueue";
import {getSessionId} from "../browser-storage/sessionId";
import {getVisitId, getVisitorId} from "../visitData";
import {isCI} from "../env";
import {AppType, CarbonUserType} from "./constants";
import {Event, EventPayload} from "./types";
import {getAnalyticsEndpoint} from "./urls";

const postEventBeacon = (payload: EventPayload, onError: (err: unknown) => unknown) => {
  const endpoint = getAnalyticsEndpoint();
  const wasDataQueueOpened = navigator.sendBeacon(
    endpoint,
    new Blob([JSON.stringify(payload)], {type: "application/json"}),
  );
  if (!wasDataQueueOpened) {
    onError(new Error("Failed to post analytics events via navigator.sendBeacon()."));
  }
};

const postEvents = async (payload: EventPayload): Promise<unknown> => {
  const response = await fetch(getAnalyticsEndpoint(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (response.status >= 400 && response.status < 500) {
    const err = `POST ${getAnalyticsEndpoint()} returned ${
      response.status
    } with body: "${await response.text()}"`;
    throw new Error(err);
  }
  return response;
};

/**
 * Checks required metadata fields to determine if we have all needed data to build event payload
 */
export const isAnalyticsReady = () =>
  !!getSessionId() && !!getDevice() && !!getVisitId() && !!getVisitorId();

export const buildEventPayload = (events: Event[]): EventPayload | null => {
  const device = getDevice();
  const visitId = getVisitId();
  const visitorId = getVisitorId();
  const userId = getCurrentUserId() || undefined;

  return device && visitId && visitorId && events
    ? {
        events,
        device,
        appInfo: {
          appType: AppType.PublicWebsite,
          appVersion: getPublicEnv().NEXT_PUBLIC_GIT_TAG || "development",
        },
        user: {
          carbonUserId: userId || undefined,
          carbonUserType: userId ? CarbonUserType.Patient : CarbonUserType.Unknown,
          visitId,
          visitorId,
        },
      }
    : null;
};

export const postQueue = (beacon = false): void => {
  const eventQueue = getEventQueue();
  clearEventQueue();
  const onError = (err: unknown) => {
    console.error(err);
    eventQueue.forEach(addToEventQueue);
  };
  const payload = eventQueue.length > 0 && buildEventPayload(eventQueue);
  if (payload && (!isbot(navigator.userAgent) || isCI)) {
    if (beacon) {
      postEventBeacon(payload, onError);
    } else {
      postEvents(payload).catch(onError);
    }
  }
};

const POST_EVENT_INTERVAL = 5000;

export const usePostEventQueue = () => {
  useEffect(() => {
    const onVisibilityStateChange = () => {
      if (document.visibilityState === "hidden") {
        postQueue(true);
      }
    };

    postQueue();
    document.addEventListener("visibilitychange", onVisibilityStateChange);
    const interval = setInterval(postQueue, POST_EVENT_INTERVAL);
    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", onVisibilityStateChange);
    };
  }, []);
};
