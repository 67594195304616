import {isBrowser} from "./isBrowser";
import {Event} from "./analytics/types";
import {safeJsonParse} from "./safeJsonParse";

/**
 * After adding a key, be sure to update `SessionStore` and `validators`
 */
export enum SessionStorageKey {
  TrafficId = "trafficId",
  SessionId = "sessionId",
  /**
   * @deprecated `viewId` should not be tracked at the app level, but at the component level. Considering using React state instead.
   */
  ViewId = "viewId",
  StartTime = "startTime",
  EventQueue = "eventQueue",
  WebBookingEmail = "webBookingEmail",
  WebBookingPatientAppEmail = "webBookingPatientAppEmail",
}

export type SessionStore = {
  [SessionStorageKey.TrafficId]: string;
  [SessionStorageKey.SessionId]: string;
  [SessionStorageKey.ViewId]: string;
  [SessionStorageKey.StartTime]: number;
  [SessionStorageKey.EventQueue]: Event[];
  [SessionStorageKey.WebBookingEmail]: string;
  // WebBookingEmail is used to change which page we render (email entry or PHI entry).
  // WebBookingPatientAppEmail is used to pre-fill a form in the patient app.
  // These two keys are different so that they see the email entry page if they enter an email,
  // get redirected to the patient app, and then hit "back". If we shared the same key then
  // they would see the PHI entry page instead.
  [SessionStorageKey.WebBookingEmail]: string;
  [SessionStorageKey.WebBookingPatientAppEmail]: string;
};

/**
 * Caution, unsafely casting return type.
 */
export function getFromSessionStorage<T extends SessionStorageKey>(name: T) {
  return safeJsonParse(safeSessionStorage()?.getItem(name)) as SessionStore[T] | undefined;
}

export function setInSessionStorage<T extends SessionStorageKey>(
  key: T,
  val: SessionStore[T],
): void {
  const storage = safeSessionStorage();
  if (storage) {
    const oldValue = storage.getItem(key);
    const newValue = JSON.stringify(val);
    if (oldValue !== newValue) {
      storage.setItem(key, newValue);

      /**
       * This is required because these events are only emitted to other
       * documents other than the ones they are created on.
       * To fix, we manually create and emit the event for the current window.
       */
      window.dispatchEvent(
        new StorageEvent("storage", {
          key,
          newValue,
          oldValue,
          cancelable: false,
          storageArea: storage,
        }),
      );
    }
  }
}

export function removeSessionStorage(key: SessionStorageKey): void {
  const storage = safeSessionStorage();
  if (storage) {
    const oldValue = storage.getItem(key);
    if (oldValue !== null) {
      storage.removeItem(key);
      window.dispatchEvent(
        new StorageEvent("storage", {
          key: key,
          newValue: null,
          oldValue,
          cancelable: false,
          storageArea: storage,
        }),
      );
    }
  }
}

function safeSessionStorage(): Storage | undefined {
  if (isBrowser()) {
    try {
      return window.sessionStorage;
    } catch (e) {
      if (!(e instanceof DOMException)) {
        throw e;
      }
    }
  }
}
