import {useMemo, useState} from "react";
import {v4} from "uuid";

import {getSessionStart} from "../../../utils/browser-storage/sessionDuration";
import {getSessionId} from "../../../utils/browser-storage/sessionId";
import {getTrafficId} from "../../../utils/browser-storage/trafficId";
import {getVisitorId} from "../../../utils/visitData";
import {makeDevice} from "../../../utils/makeDevice";
import {SessionState} from "./types";

export default function useSessionState(
  ipAddress: string | undefined,
  lng: string | undefined,
): SessionState {
  const [sessionId] = useState(getSessionId() || v4());
  const [trafficId] = useState(getTrafficId() || v4());
  const [sessionStart] = useState(getSessionStart() || Date.now());
  const device = useMemo(
    () =>
      makeDevice({
        ipAddress: ipAddress,
        userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "",
        languageCode: lng,
        deviceId: getVisitorId() || v4(),
      }),
    [ipAddress, lng],
  );

  return useMemo(
    () => ({
      sessionId,
      trafficId,
      sessionStart,
      device,
    }),
    [sessionId, trafficId, sessionStart, device],
  );
}
