"use client";

import {useContext, createContext, PropsWithChildren} from "react";

type AppClientLanguageContextType = {
  lang?: string;
};

const defaultContext: AppClientLanguageContextType = {};

export const AppClientLanguageContext = createContext(defaultContext);

export const useAppClientLanguage = () => useContext(AppClientLanguageContext);

export const AppClientLanguageProvider = ({children, lang}: PropsWithChildren<{lang: string}>) => {
  return (
    <AppClientLanguageContext.Provider value={{lang}}>{children}</AppClientLanguageContext.Provider>
  );
};
