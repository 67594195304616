import {useEffect} from "react";

import {sfCenterPos} from "../components/_common/_constants";
import {Coordinate} from "../store/types";
import {getGeolocation, setGeolocation} from "../utils/browser-storage/location";
import {CFGeolocation} from "../utils/locationTypes";
import {QueryState, QueryStatus, useQueryController} from "./useQueryController";
import {withValidation} from "../utils/fetch/fetches";
import {NEXT_PUBLIC_WORKER_URL} from "../publicEnv";
import {queueEvent} from "src/utils/analytics/eventQueue";
import {EventCategory, EventType} from "src/utils/analytics/constants";

const setDefaultLatLong = () => {
  setGeolocation(sfCenterPos);
  return sfCenterPos;
};

const cfGeolocate = async (): Promise<CFGeolocation> => {
  const response = await withValidation()(`${NEXT_PUBLIC_WORKER_URL}/geolocation`, {
    method: "POST",
  });

  const body = await response.json();
  return {
    x: body.location.lat,
    y: body.location.lng,
    regionCode: body.location.regionCode,
  };
};

const logGeolocationEvent = (coordinate: {latitude: number; longitude: number}) =>
  queueEvent({
    category: EventCategory.Location,
    typeId: EventType.LocationGeoLocateSucceeded,
    extraData: {
      result: {
        coords: {
          latitude: coordinate.latitude,
          longitude: coordinate.longitude,
        },
        timestamp: Date.now(),
      },
    },
  });

const geolocateAndSave = async (browserPrompt = false): Promise<Coordinate> => {
  try {
    const position = await cfGeolocate();

    if (browserPrompt && "geolocation" in navigator) {
      // Try to get the geolocation from the browser
      navigator.geolocation.getCurrentPosition(
        ({coords: {latitude, longitude}}) => {
          const browserPos = {
            ...position,
            x: latitude,
            y: longitude,
          };
          logGeolocationEvent({latitude, longitude});
          setGeolocation(browserPos);
          return browserPos;
        },
        () => {
          logGeolocationEvent({latitude: position.x, longitude: position.y});
          // If the user rejected the request or if it failed for other reasons, fall back to API
          setGeolocation(position);
        },
      );
    } else {
      logGeolocationEvent({latitude: position.x, longitude: position.y});
      // If the geolocation API is not available, use the fallback method
      setGeolocation(position);
    }

    return position;
  } catch {
    return setDefaultLatLong();
  }
};

export const geolocateUser = async (forceCfGeolocate = false): Promise<Coordinate> =>
  forceCfGeolocate ? geolocateAndSave(forceCfGeolocate) : getGeolocation() || geolocateAndSave();

export const useGeolocateUser = () => {
  useEffect(() => {
    geolocateUser(false);
  }, []);
};

/**
 * Hook for getting the user's current coordinates.
 */
export const useUserCoordinates = ({skip}: {skip?: boolean} = {}): QueryState<Coordinate> => {
  const coordinates = getGeolocation();
  const query = useQueryController({
    fn: geolocateAndSave,
    cacheKey: "cf-geolocation-query",
    initialValue: sfCenterPos,
    ifRejectedValue: sfCenterPos,
    skip: skip || !!coordinates,
  });
  if (coordinates) {
    return {
      data: coordinates,
      status: QueryStatus.SUCCESS,
      isSettled: true,
      isLoading: false,
      isInitiated: true,
    };
  }
  return query;
};
