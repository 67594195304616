import {getCookie} from "cookies-next";
import {ServerResponse, IncomingMessage} from "http";
import {AnyNextRequest, GetServerSidePropsRequest} from "./nextRequestType";
import {MsMap} from "src/constants/MsMap";

export enum CookieKey {
  VisitId = "visitId",
  VisitorId = "visitorId",
}

/**
 *
 * @param cookieString the value of either `document.cookie` or `req.headers.cookie`
 */
export const getCookiesFromString = (cookieString = ""): Record<string, string> =>
  Object.fromEntries(cookieString?.split("; ").map(cookie => cookie.split("=")) || []);

function getCookiesFromStrings(cookieStrings: string[]): Record<string, string> {
  return cookieStrings.reduce((acc, next) => ({...acc, ...getCookiesFromString(next)}), {});
}

function getCookiesFromStringOrArray(
  cookieStringOrArray: string | string[],
): Record<string, string> {
  return Array.isArray(cookieStringOrArray)
    ? getCookiesFromStrings(cookieStringOrArray)
    : getCookiesFromString(cookieStringOrArray);
}

function getSetCookiesFromResponse(res: ServerResponse<IncomingMessage>): string[] {
  const rawCookies = res.getHeader("Set-Cookie");
  if (!rawCookies) {
    return [];
  }
  if (typeof rawCookies === "number") {
    return [];
  }
  if (Array.isArray(rawCookies)) {
    return rawCookies;
  }
  return [rawCookies];
}

export function expiresInOneYearOptions() {
  return {expires: new Date(Date.now() + MsMap.ONE_YEAR)};
}

/**
 * When we set a cookie via middleware and try to use in getServerSideProps,
 * it is not available in request or response cookies. Instead, it is available
 * via the Set-Cookie header in the response.
 *
 * If response is passed, it will be checked for a Set-Cookie header first.
 * If request is passed, it will be checked next for a cookie.
 * If neither are passed, it will try to get the cookies from the browser, which
 *   will only work in the browser.
 */
export function getCookieValue(
  key: string,
  req?: AnyNextRequest,
  res?: ServerResponse<IncomingMessage>,
) {
  if (res) {
    const cookiesFromSetCookie = getCookiesFromStringOrArray(getSetCookiesFromResponse(res));
    if (key in cookiesFromSetCookie) {
      return cookiesFromSetCookie[key];
    }
  }
  // Note: cookies-next types are wrong, it can also accept middleware request
  return getCookie(key, {req: req as GetServerSidePropsRequest | undefined});
}
