import React from "react";
import i18nextI18n from "i18next";
import {I18nNamespace} from "./constants";
import {Trans as TransWithoutContext} from "react-i18next/TransWithoutContext";
import {TranslateFn} from "@i18n/types";
import {DEFAULT_NS} from "./constants";

type Props = Omit<Parameters<typeof TransWithoutContext>[0], "shouldUnescape" | "i18n"> & {
  t: TranslateFn;
  ns?: I18nNamespace;
};

const Trans = (props: Props): React.ReactElement => {
  const {ns, children, t, ...otherProps} = props;

  return (
    <TransWithoutContext
      shouldUnescape
      i18n={i18nextI18n}
      t={t}
      ns={ns ?? DEFAULT_NS}
      {...otherProps}
    >
      {children}
    </TransWithoutContext>
  );
};

export default Trans;
