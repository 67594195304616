import {getSessionId, setSessionId} from "src/utils/browser-storage/sessionId";
import {SessionState} from "./types";
import {getSessionStart, setSessionStart} from "src/utils/browser-storage/sessionDuration";
import {getTrafficId, setTrafficId} from "src/utils/browser-storage/trafficId";
import {setDevice} from "src/utils/browser-storage/device";

export default function initializeSession({
  sessionId,
  sessionStart,
  trafficId,
  device,
}: SessionState): void {
  if (!getSessionId()) setSessionId(sessionId);
  if (!getSessionStart()) setSessionStart(sessionStart);
  if (!getTrafficId()) setTrafficId(trafficId);
  setDevice(device);
}
