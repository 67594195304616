import {InitOptions} from "i18next";
import {selectable} from "../../languages";
import {DEFAULT_LANG, DEFAULT_NS} from "./constants";

export const DEFAULT_I18N_OPTIONS: InitOptions = {
  supportedLngs: selectable,
  fallbackLng: DEFAULT_LANG,
  defaultNS: DEFAULT_NS,
  fallbackNS: DEFAULT_NS,
};
