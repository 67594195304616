import {ValueOf} from "../../types";
import {SupportedLanguage} from "./types";

export type I18nNamespace = ValueOf<typeof I18nNamespace>;
export const I18nNamespace = {
  MAIN: "website",
  DB: "website-db",
  WEB_SCHEDULING: "web-scheduling",
  CONFIRM_IDENTITY: "confirm-identity",
} as const;

export const DEFAULT_LANG: SupportedLanguage = "en";
export const DEFAULT_NS: I18nNamespace = I18nNamespace.MAIN;
